export default Object.freeze({
	IsActivated: 'IsActivated',
	Success: 'Success',
	InReview: 'IN-REVIEW',
	OnboardingRequired:
		'Hello! You need to complete your Onboarding to access this feature',
	Failed: 'Failed',
	LIMIT_EXCEEDED:
		'Account limit is exceeded for this transaction, kindly contact the Admin',
	TokenSent: 'Token Sent',
	RouteParams: 'RouteParams',
	business: 'business',
	walletBalance: 'walletBalance',
	TransactionsSummary: 'TransactionsSummary',
	walleTransferLimit: 'walleTransferLimit',
	OfflineTransactions: 'OfflineTransactions',
	WalletTransactions: 'WalletTransactions',
	WeekSummary: 'WeekSummary',
	NewBeneficiaryPayload: 'NewBeneficiaryPayload',
	SavedBeneficiaryPayload: 'SavedBeneficiaryPayload',
	TransferPayload: 'TRANSFERPAYLOAD',
	Banks: 'Banks',
	Sent: 'Sent',
	INVALID_PIN: 'Invalid Pin',
	SET_UP_PIN: 'Please set your pin to use this feature',
	LOW_WALLET_BALANCE: 'Account balance is low, kindly top up',
	Beneficiaries: 'Beneficiaries',
	MakerCheckerUserType: {
		REQUESTER: 'REQUESTER',
		REVIEWER: 'REVIEWER',
		APPROVER: 'APPROVER',
	},
	CATEGORY: {
		WALLET: 'WALLET',
		OFFLINE: 'OFFLINE',
	},
	TRANSACTION_TYPE: {
		EXPENSE: 'EXPENSE',
		INCOME: 'INCOME',
		INCOME_DEBT: 'INCOME-DEBT',
		EXPENSE_DEBT: 'EXPENSE-DEBT',
	},
	STATUS: {
		APPROVED: 'APPROVED',
		PENDING: 'PENDING',
		VOID: 'VOID',
		FAILED: 'FAILED',
		PAID: 'PAID',
		IGNORE: 'IGNORED',
		IN_REVIEW: 'IN REVIEW',
		REVIEWED: 'REVIEWED',
		PARTIALLY_REVIEWED: 'PARTIALLY REVIEWED',
		REQUEST_CHANGE: 'REQUEST CHANGE',
		PROCESSING: 'PROCESSING',
		REVERSED: 'REVERSED',
	},
	TRANSACTION_STATUS: {
		PAID: 'PAID',
		VOID: 'VOID',
		UNPAID: 'UNPAID',
		PARTIALLY_PAID: 'PARTIALLY-PAID',
		FULLY_PAID: 'FULLY-PAID',
	},
	QUOTATION_STATUS: {
		DRAFT: 'DRAFT',
		VOID: 'VOID',
		ORDERED: 'ORDERED',
		EXPIRED: 'EXPIRED',
	},
	MissingWithdrawalAccount: 'Kindly Setup Your Withdrawal Account',
	RoutePath: 'RoutePath',
	Routes: {
		Home: '/',
		HomeEmpty: '',
		Login: '/login',
		// SignUp: '/signup',
		SignUp: 'https://fashcreatives.com/auth/signup',
		FashHome: 'https://fashcreatives.com/',
		Error404: '/error-404',
		PaymentLinkCheckout: '/payments/:id',
		SplitPayments: '/split-payments',
		// Faqs: '/faqs',
		Faqs: 'https://fashcreatives.com/faqs',
		MakeBookings: '/bookings/:id',
		// About: '/about',
		About: 'https://fashcreatives.com/about',
		// Terms: '/terms',
		Terms: 'https://fashcreatives.com/terms',
		// Privacy: '/privacy-policy',
		Privacy: 'https://fashcreatives.com/privacy-policy',
		Contact: '/contact',
		SubExpired: '/subcription-expired',
		Article: '/article',
		Article1: '/benefits-of-digital-business-management',
		Article2: '/business-management-solutions',
		Article3: '/mima-business-bill-payments',
		Article4: '/payroll-management-solution',
		Article5: '/best-business-management-software',
		Accounting: '/accounting',
		BlogLogin: '/blog/auth/login',
		BlogLanding: 'https://fashcreatives.com/blog',
		BlogAdminLanding: '/blog/admin-landing',
		BlogAddNew: '/blog/admin-new-user',
		BlogAddNewPost: '/blog/admin-new-post',
		BlogSinglePost: '/blog/:id',
		BlogPost1: '/blog/business-accounting-the-pillar-of-financial-management',
		Orders: '/orders',
		Sitemap: '/sitemap_index.xml',
		ApiKeys: '/api-keys',
		ViewMoreDesign: '/view-more-design',
		PaymentCheckout: '/payment-checkout',
		Catalogue: '/store/:name',
		SizeGuide: '/size-guide',
		// PartnerHome: '/partnership',
		PartnerHome: 'https://fashcreatives.com/partnership',
		PartnerDashboard: '/partner-dashboard',
		PartnerSignUp: '/partner-signup',
		PartnerLogin: '/partner-login',
		SingleCatalog: '/catalog/:slug',
		RequestOrder: '/request-order',
		WebsiteAbout: '/website-about',
		WebsiteContact: '/website-contact',
		WebsiteTermsOfService: '/website-terms-of-service',
		WebsiteRefundPolicy: '/website-refund-policy',
		WebsiteFaqs: '/website-faqs',
		WebsiteCart: '/website-cart',
		WebsiteShop: '/website-shop',
		WebsiteGallery: '/website-gallery',
		WebsiteSearch: '/website-search',
		PublicCatalogue: '/public-catalogue',
		SinglePublicCatalogue: '/public-catalogue/single',
		ThemeHome: '/theme',
		ThemeShop: '/themeShop',
	},
	IsOnBoardingDone: 'IsOnBoardingDone',
	OtpType: {
		FORGETPASSWORD: 'FORGETPASSWORD',
		ONBOARDING: 'ONBOARDING',
		BVNONBOARDING: 'BVNONBOARDING',
		PARTNERSIGNUP: 'PARTNERSIGNUP',
		PARTNERFORGETPASSWORD: 'PARTNERFORGETPASSWORD',
	},
	SUBSCRIPTION_TYPE: {
		BASIC: 'Basic',
		PREMIUM: 'Premium',
		ENTERPRISE: 'Enterprise',
	},
	PLAN_STATUS: {
		ACTIVE: 'ACTIVE',
		LOCKED: 'LOCKED',
		EXPIRED: 'EXPIRED',
	},

	FEEDBACK: {
		CUSTOMERCREATE: 'CUSTOMER-CREATE',
		CUSTOMERUPDATE: 'CUSTOMER-UPDATE',
		INVOICECREATE: 'INVOICE-CREATE',
		INVOICEUPDATE: 'INVOICE-UPDATE',
		INVOICEEDIT: 'INVOICE-EDIT',
		RECEIPTCREATE: 'RECEIPT-CREATE',
		WALLETCREATEVIRTUALBUSINESSBANKACCOUNT:
			'WALLET-CREATE-VIRTUAL-BUSINESS-BANK-ACCOUNT',
		WALLETWITHDRAWAL: 'WALLET-WITHDRAWAL',
		WALLETMAKEPAYMENT: 'WALLET-MAKE-PAYMENT',
		// WALLETFUNDTRANSFER: 'WALLET-FUND-TRANSFER',
		WALLETFUNDWALLET: 'WALLET-FUND-WALLET',
		WALLETEXCHANGE: 'WALLET-EXCHANGE',
		PETTYCASHCREATE: 'PETTY-CASH-CREATE',
		PETTYCASHMAKEPAYMENT: 'PETTY-CASH-MAKE-PAYMENT',
		// PETTYCASHFUNDWALLET: 'PETTY-CASH-FUND-WALLET',
		PAYBILLSBUYAIRTIMEDATA: 'PAY-BILLS-BUY-AIRTIME/DATA',
		PAYBILLSBUYELECTRICITY: 'PAY-BILLS-BUY-ELECTRICITY',
		PAYBILLSBUYCABLETV: 'PAY-BILLS-BUY-CABLE-TV',
		BOOKINGCREATE: 'BOOKING-CREATE',
		BOOKINGEDIT: 'BOOKING-EDIT',
		PAYMENTLINKCREATE: 'PAYMENT-LINK-CREATE',
		PAYMENTLINKEDIT: 'PAYMENT-LINK-EDIT',
		EMPLOYEEADD: 'EMPLOYEE-ADD',
		EMPLOYEEADDBULK: 'EMPLOYEE-ADD-BULK',
		EMPLOYEETERMINATE: 'EMPLOYEE-TERMINATE',
		EMPLOYEEADDDEPARTMENT: 'EMPLOYEE-ADD-DEPARTMENT',
		EMPLOYEEADDEMPLOYEELOAN: 'EMPLOYEE-ADD-EMPLOYEE-LOAN',
		TRANSACTIONSADDINCOME: 'TRANSACTIONS-ADD-INCOME',
		TRANSACTIONSADDEXPENSE: 'TRANSACTIONS-ADD-EXPENSE',
		TRANSACTIONSADDDEBT: 'TRANSACTIONS-ADD-DEBT',
		TRANSACTIONSTAG: 'TRANSACTIONS-TAG',
		VENDORADD: 'VENDOR-ADD',
		VENDOREDIT: 'VENDOR-EDIT',
		STOCKADDPRODUCTTYPE: 'STOCK-ADD-PRODUCT-TYPE',
		STOCKADDSTOCK: 'STOCK-ADD-STOCK',
		STOCKADDUPDATEQUANTITY: 'STOCK-ADD-UPDATE-QUANTITY',
		STOCKEDITSTOCK: 'STOCK-EDIT-STOCK',
		STOCKEDITPRODUCTTYPE: 'STOCK-EDIT-PRODUCT-TYPE',
		MULTITENANCYADD: 'MULTI-TENANCY-ADD',
		BUSINESSREPORTDOWNLOAD: 'BUSINESS-REPORT-DOWNLOAD',
	},

	FLOW_TYPE: {
		All: 'All',
		Income: 'Income',
		Debt: 'Debt',
		Funding: 'Funding',
		Expense: 'Expense',
		Withdraw: 'Withdraw',
		Refund: 'Refund',
		Untagged: 'Untagged',
		Investment: 'Investment',
		Gifts: 'Gifts',
		Assets: 'Assets',
		Loan: 'Loan',
	},

	apiQueryKey: {
		Transactions: {
			TransactionList: 'transactionList',
		},
	},
	fashStyle: {
		collectionInHeaderMenu: 'DISPLAY_ON_MENU_BAR',
	},
	errorMsg: {
		styleNotFound: 'Style Record Not Found',
		storeNotFound: 'StoreFront Record Not Found',
	},
});
