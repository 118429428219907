import React from 'react';
import { MimaText } from '../../components';
import { amountFormatter } from '../../utils/utils';

const useShop = ({ ImageSlideshow, currencyCode, switchedPrice, styles }) => {
	const tableColumns = [
		{
			header: 'Image',
			accessorKey: 'imageUrls',
			cell: item => {
				const imageUrls = item?.getValue();
				const variations = item?.row?.original?.variations;
				const sellingPrices = variations[0]?.sellingPrices;
				const sellingPrice = sellingPrices.filter(
					x => x?.currencyCode === currencyCode
				)[0];

				const isSoldOut = variations[0]?.isSoldOut;
				return imageUrls ? (
					<ImageSlideshow
						imageUrls={imageUrls}
						sellingPrice={sellingPrice}
						isSoldOut={isSoldOut}
					/>
				) : (
					<span>N/A</span>
				);
			},
		},
		{
			header: 'Product Name',
			accessorKey: 'name',
			cell: item => {
				const name = item?.getValue();

				return <MimaText variant="small">{name}</MimaText>;
			},
		},
		{
			header: 'Selling Price',
			accessorKey: 'variations',
			cell: item => {
				const variations = item?.getValue();
				const sellingPrices = variations[0]?.sellingPrices;
				const filteredPrice = sellingPrices.filter(
					x => x?.currencyCode === currencyCode
				)[0];
				const withOutMaterial = filteredPrice?.withoutMaterial;
				const withMaterial = filteredPrice?.withMaterial;
				const shownPrice = () => {
					if (withMaterial > withOutMaterial) {
						return withMaterial;
					} else if (withMaterial < withOutMaterial) {
						return withOutMaterial;
					} else if (withOutMaterial) {
						return withOutMaterial;
					} else if (withMaterial) {
						return withMaterial;
					} else {
						return '';
					}
				};

				const convertedPrice = () => {
					return switchedPrice(currencyCode, shownPrice());
				};
				const convertedPromoPrice = () => {
					return switchedPrice(currencyCode, filteredPrice?.onSaleSlashPrice);
				};

				const formattedPrice =
					shownPrice() !== ''
						? amountFormatter(convertedPrice()?.currencyCode).format(
								convertedPrice()?.price
							)
						: '';
				const formattedPromoPrice = amountFormatter(
					convertedPromoPrice()?.currencyCode
				).format(convertedPromoPrice()?.price);

				return (
					<>
						{filteredPrice?.onSaleSlashPrice > 0 ? (
							<div className={styles.sales}>
								<p className={styles.sales__cancel}>{formattedPrice}</p>
								<p>{formattedPromoPrice}</p>
							</div>
						) : (
							<MimaText>{formattedPrice ? formattedPrice : ''}</MimaText>
						)}
					</>
				);
			},
		},
	];
	return {
		tableColumns,
	};
};

export default useShop;
