import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { apiRequest } from '../utils/useAPIRequest';
import constant from '../utils/constant';
import { catalogStore } from '../stores';
import { encodeQueryData } from '../utils/utils';
import { queryClient } from '../App';

const staleTime = 1000 * 60 * 10;

const useGetStyleCatalog = query => {
	return useQuery({
		queryKey: [
			// id,
			'getStyleCatalog',
			query,
		],
		queryFn: () => {
			return apiRequest({
				url: `/fashion/store-styles?${query}`,
				method: 'get',
			});
		},
		staleTime: staleTime,
		keepPreviousData: true,
	});
};

const getStyleList = async query => {
	const { status, errorMessage, data } = await apiRequest({
		url: `/fashion/store-styles?${query}`,
		method: 'get',
	});
	if (status === constant.Success) {
		const styles = data?.styles?.map(x => ({
			key: x._id,
			value: x.name,
		}));

		catalogStore.setStyleList(styles);
	}
	return { status, errorMessage, data };
};
const getSingleStyleCatalog = async query => {
	const { status, errorMessage, data } = await apiRequest({
		url: `/fashion/store-style-by-slug?${query}`,
		method: 'get',
	});
	if (status === constant.Success) {
		catalogStore.setSingleCatalog(data);
	}
	return { status, errorMessage, data };
};

const getCollections = async (subDomain, currencyCode) => {
	const { status, errorMessage, data } = await apiRequest({
		url: `/fashion/collections?subDomain=${subDomain}`,
		// url: `/fashion/collections?subDomain=${subDomain}&currencyCode=${currencyCode}`,
		method: 'get',
	});
	if (status === constant.Success) {
		const nextCallTime = new Date().getTime() + 3600 * 1000;
		catalogStore.setCollections(data);
		catalogStore.setNextCollectionsCall(nextCallTime);
	}
	return { status, errorMessage };
};

const requestDesign = async payload => {
	const { status, errorMessage } = await apiRequest({
		url: '/fashion/style/request',
		method: 'post',
		payload,
	});
	if (status === constant.Success) {
		return { status };
	}
	return { status, errorMessage };
};

const createCataloguePaymentCheckout = async payload => {
	const { status, data, errorMessage } = await apiRequest({
		url: '/invoices/checkout',
		method: 'post',
		payload,
	});
	if (status === constant.Success) {
		return { status: constant.Success, data };
	}
	return { errorMessage, status };
};
const bookingCheckout = async payload => {
	const { status, data, errorMessage } = await apiRequest({
		url: '/invoices/accept-booking-invoice',
		method: 'post',
		payload,
	});
	if (status === constant.Success) {
		return { status: constant.Success, data };
	}
	return { errorMessage, status };
};
const createOrder = async payload => {
	const { status, data, errorMessage } = await apiRequest({
		url: '/orders/order-by-customer',
		method: 'post',
		payload,
	});
	if (status === constant.Success) {
		// paymentLinkStore.setCharges();

		return { status: constant.Success, data };
	}
	return { errorMessage, status };
};

const useGetPublicCatalog = (
	publicCatalogTableQuery,
	filterQuery,
	searchQuery
) => {
	const query = useMemo(() => {
		if (searchQuery) {
			return `${publicCatalogTableQuery}&${filterQuery}&search=${searchQuery}`;
		} else if (filterQuery) {
			return `${publicCatalogTableQuery}&${filterQuery}`;
		} else {
			return publicCatalogTableQuery;
		}
	}, [filterQuery, publicCatalogTableQuery, searchQuery]);

	return useQuery({
		queryKey: [
			// id,
			'getPublicCatalog',
			query,
		],
		queryFn: () => {
			return apiRequest({
				url: `/fashion/catalogue-styles?${query}`,
				method: 'get',
			});
		},
		staleTime: staleTime,
		keepPreviousData: true,
	});
};

const useGetPublicCatalogInfinite = (
	searchQuery,
	getPageValue,
	getPageTotalCount,
	limit
) => {
	const query = useMemo(() => {
		return {
			...(searchQuery ? { search: searchQuery } : {}),
			limit: limit,
			offset: 1,
		};
	}, [searchQuery, limit]);

	return useInfiniteQuery({
		queryKey: ['getPublicCatalog', searchQuery],
		queryFn: ({ pageParam = 1 }) => {
			const encodeQuery = encodeQueryData({
				...query,
				offset: pageParam || 1,
			});

			return apiRequest({
				url: `/fashion/catalogue-styles?${encodeQuery}`,
				method: 'get',
			});
		},
		keepPreviousData: true,
		staleTime: staleTime,
		getNextPageParam: (lastPage, allPages) => {
			const allPagesCount =
				allPages.flatMap(page => getPageValue(page) || []).length || 0;

			const totalCount = getPageTotalCount(lastPage) || 0;
			const limit = query.limit || 50;

			if (totalCount > allPagesCount) {
				const page = Math.floor(allPagesCount / limit);
				return page + 1;
			}

			return undefined;
		},
	});
};

const invoicePaymentConfirm = async id => {
	const { status, data, errorMessage } = await apiRequest({
		url: `/invoices/confirm-payment/${id}`,
		method: 'get',
	});

	if (status === constant.Success) {
		return { status: constant.Success, data };
	}
	return { errorMessage, status };
};
const getUserTheme = async query => {
	const { status, data, errorMessage } = await apiRequest({
		url: `/fashion/store-styles?${query}`,
		method: 'get',
	});

	if (status === constant.Success) {
		return { status: constant.Success, data };
	}
	return { errorMessage, status };
};

const newsletterSignup = async payload => {
	const { status, errorMessage } = await apiRequest({
		url: '/fashion/newsletter/contact',
		method: 'post',
		payload,
	});
	if (status === constant.Success) {
		return { status };
	}
	return { status, errorMessage };
};

const sendContactMessage = async payload => {
	const { status, errorMessage } = await apiRequest({
		url: '/fashion/contact/business',
		method: 'post',
		payload,
	});
	if (status === constant.Success) {
		return { status };
	}
	return { status, errorMessage };
};

const createThemeData = async payload => {
	const { status, errorMessage, data } = await apiRequest({
		url: '/fashion/theme/data',
		method: 'post',
		payload,
	});
	if (status === constant.Success) {
		catalogStore.setWebConfig(data?.website);
		return { status, data };
	}
	return { status, errorMessage };
};

const updateThemeData = async (payload, themeId) => {
	const { status, errorMessage, data } = await apiRequest({
		url: `/fashion/theme/data?themeId=${themeId}`,
		method: 'put',
		payload,
	});
	if (status === constant.Success) {
		catalogStore.setWebConfig(data?.website);
		return { status, data };
	}
	return { status, errorMessage };
};

const uploadDocument = async payload => {
	const { status, errorMessage, data } = await apiRequest({
		url: '/businesses/documents/upload',
		method: 'post',
		payload,
		hasImageUpload: true,
	});
	if (status === constant.Success) {
		return { status: constant.Success, data };
	}
	return { errorMessage, status, data };
};

const configureWebsite = async payload => {
	const { status, errorMessage, data } = await apiRequest({
		url: '/settings/update-setting',
		method: 'patch',
		payload,
	});
	if (status === constant.Success) {
		queryClient.invalidateQueries({ queryKey: ['getStyleCatalog'] });
		catalogStore?.setWebConfig(data?.website);

		return { status };
	}
	return { status, errorMessage };
};

const StyleCatalogController = {
	useGetStyleCatalog,
	getSingleStyleCatalog,
	requestDesign,
	getCollections,
	createCataloguePaymentCheckout,
	useGetPublicCatalog,
	useGetPublicCatalogInfinite,
	createOrder,
	getStyleList,
	invoicePaymentConfirm,
	getUserTheme,
	newsletterSignup,
	sendContactMessage,
	createThemeData,
	updateThemeData,
	uploadDocument,
	configureWebsite,
	bookingCheckout,
};

export default StyleCatalogController;
