import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { catalogStore } from '../../stores';
import constant from '../../utils/constant';
import { amountFormatter } from '../../utils/utils';

const useCart = (fashCart, setFashCart) => {
	const navigate = useNavigate();

	const { newShippingInfo } = catalogStore;

	// const [fashCart, setFashCart] = useState([]);
	const [quantities, setQuantities] = useState([]);

	useEffect(() => {
		const storedFashCart = JSON.parse(localStorage.getItem('fashCart')) || [];
		setQuantities(storedFashCart?.map(cart => cart?.quantity));
		setFashCart(storedFashCart);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// const [loading, setLoading] = useState({});

	// const asyncDebounce = useAsyncDebounce(updateCartQuantity, 1000);

	const updateCartQuantityHandler = async (cart, newQuantities, index) => {
		const updatedCart = fashCart.map((item, index) => {
			return {
				...item,
				quantity: newQuantities[index],
				amount: newQuantities[index] * item.amountPerItem,
			};
		});
		setFashCart(updatedCart);
		localStorage.setItem('fashCart', JSON.stringify(updatedCart));
	};

	const handleQtyChange = (index, event, cart) => {
		const newQuantities = [...quantities];
		newQuantities[index] = parseInt(event.target.value || '0');
		setQuantities(newQuantities);
		updateCartQuantityHandler(cart, newQuantities, index);
	};

	const handleIncrement = (index, cart) => {
		const newQuantities = [...quantities];
		newQuantities[index]++;
		setQuantities(newQuantities);

		updateCartQuantityHandler(cart, newQuantities, index);
	};

	const handleDecrement = (index, cart) => {
		const newQuantities = [...quantities];

		if (newQuantities[index] === 1) {
			return removeItemHandler(index);
		}

		if (newQuantities[index] > 1) {
			newQuantities[index]--;
			setQuantities(newQuantities);
		}

		updateCartQuantityHandler(cart, newQuantities, index);
	};

	const removeItemHandler = index => {
		// setLoading(prevState => ({ ...prevState, [id]: true }));
		// await removeFromCart(id);
		// setLoading(prevState => ({ ...prevState, [id]: false }));
		const newQuantities = [...quantities];
		const updatedCartItems = [...fashCart];

		updatedCartItems.splice(index, 1);
		newQuantities.splice(index, 1);

		setFashCart(updatedCartItems);
		setQuantities(newQuantities);

		localStorage.setItem('fashCart', JSON.stringify(updatedCartItems));
		setFashCart(updatedCartItems);
	};

	const calcSubtotalPrice = items => {
		return items?.reduce((totalPrice, cart) => {
			const { amountPerItem, quantity } = cart;
			return totalPrice + amountPerItem * quantity;
		}, 0);
	};

	const checkoutHandler = () => {
		catalogStore.setCatalogOrderInfo(fashCart);
		navigate(constant.Routes.PaymentCheckout);
	};

	const subTotalPrice = useMemo(() => {
		const subTotalPrice =
			fashCart?.length > 0 ? calcSubtotalPrice(fashCart) : 0;

		return subTotalPrice;
	}, [fashCart]);

	const paystackCharge = useMemo(() => {
		return subTotalPrice < 2500
			? subTotalPrice * 0.015
			: subTotalPrice * 0.015 + 100 < 2000
				? subTotalPrice * 0.015 + 100
				: 2000;
	}, [subTotalPrice]);

	const preChargesTotal = useMemo(() => {
		const totalWithShipping =
			calcSubtotalPrice(fashCart) + newShippingInfo?.shippingFee;
		const totalWithoutShipping = calcSubtotalPrice(fashCart);

		const subTotalPrice =
			fashCart?.length > 0 &&
			totalWithoutShipping > newShippingInfo?.freeShippingForOrderAbove &&
			newShippingInfo?.freeShippingForOrderAbove > 0
				? totalWithoutShipping
				: fashCart?.length > 0
					? totalWithShipping
					: 0;

		return subTotalPrice;
	}, [fashCart, newShippingInfo]);

	const emptyCartText = 'Your cart is currently empty';

	const emptyButtonHandler = route => {
		navigate(route);
	};

	const totalPrice = useMemo(() => {
		// if (fashCart?.[0]?.currencyCode === 'NGN') {
		// 	return amountFormatter(fashCart?.[0]?.currencyCode || 'NGN').format(
		// 		preChargesTotal + paystackCharge
		// 	);
		// }

		// return amountFormatter(fashCart?.[0]?.currencyCode || 'USD').format(
		// 	preChargesTotal
		// );
		return amountFormatter(fashCart?.[0]?.currencyCode || 'NGN').format(
			subTotalPrice
		);
	}, [subTotalPrice, fashCart]);

	return {
		fashCart,
		handleQtyChange,
		handleDecrement,
		removeItemHandler,
		handleIncrement,
		quantities,
		calcSubtotalPrice,
		checkoutHandler,
		subTotalPrice,
		preChargesTotal,
		paystackCharge,
		emptyCartText,
		emptyButtonHandler,
		totalPrice,
	};
};

export default useCart;
