// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Switch_switch__n\\+oYF {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.Switch_switch__on__QAQH3 {
  background-color: var(--color-dark);
}
.Switch_switch__off__wD0-U {
  background-color: var(--color-grey);
}
.Switch_switch__gen__aDkOr {
  content: "";
  width: 5rem;
  height: 3rem;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
}
.Switch_switch__nob__LdGgW {
  content: "";
  width: 2rem;
  height: 2rem;
  border-radius: 5px;
  background-color: var(--color-white);
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
  position: absolute;
  left: 5px;
}
.Switch_switch__nob__on__3DVaI {
  left: 2.5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Switch/Switch.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,SAAA;EACA,mBAAA;AACJ;AAEI;EACI,mCAAA;AAAR;AAGI;EACI,mCAAA;AADR;AAII;EACI,WAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;AAFR;AAKI;EACI,WAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,oCAAA;EACA,gCAAA;EACA,2CAAA;EACA,kBAAA;EACA,SAAA;AAHR;AAMQ;EACI,YAAA;AAJZ","sourcesContent":[".switch {\n    display: flex;\n    gap: 1rem;\n    align-items: center;\n\n\n    &__on {\n        background-color: var(--color-dark);\n    }\n\n    &__off {\n        background-color: var(--color-grey);\n    }\n\n    &__gen {\n        content: \"\";\n        width: 5rem;\n        height: 3rem;\n        border-radius: 5px;\n        padding: 5px;\n        cursor: pointer;\n        position: relative;\n        display: flex;\n        align-items: center;\n    }\n\n    &__nob {\n        content: \"\";\n        width: 2rem;\n        height: 2rem;\n        border-radius: 5px;\n        background-color: var(--color-white);\n        transition: all 0.2s ease-in-out;\n        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);\n        position: absolute;\n        left: 5px;\n\n\n        &__on {\n            left: 2.5rem;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"switch": `Switch_switch__n+oYF`,
	"switch__on": `Switch_switch__on__QAQH3`,
	"switch__off": `Switch_switch__off__wD0-U`,
	"switch__gen": `Switch_switch__gen__aDkOr`,
	"switch__nob": `Switch_switch__nob__LdGgW`,
	"switch__nob__on": `Switch_switch__nob__on__3DVaI`
};
export default ___CSS_LOADER_EXPORT___;
