import React, { useEffect, useState } from 'react';
import styles from '../../assets/styles/PaymentLink/PaymentLinkCheckout.module.scss';
import { amountFormatter } from '../../utils/utils';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { useCart } from '../../hooks/pageHooks';
import { useMediaQuery } from 'react-responsive';
import { catalogStore } from '../../stores';
import { MimaText } from '../../components';
import { useCatalogUtility } from '../../hooks';

const OrderSummary = () => {
	const { fashCart, setFashCart } = useCatalogUtility();
	const { totalPrice, preChargesTotal } = useCart(fashCart, setFashCart);

	const isBelow800 = useMediaQuery({
		query: '(max-width: 800px)',
	});

	const [orderSummary, setOrderSummary] = useState(false);
	const [orderSolid, setOrderSolid] = useState(false);

	const handleScroll = () => {
		const headerHeight =
			document?.querySelector('#checkoutHeader')?.offsetHeight;
		if (window.scrollY > headerHeight) {
			setOrderSolid(true);
		} else {
			setOrderSolid(false);
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const { catalogOrderInfo, newShippingInfo } = catalogStore || {};

	return (
		<div
			className={`${styles.checkout__order} ${orderSolid && !isBelow800 ? styles.checkout__order__solid : ''}`}>
			{isBelow800 ? (
				<div className={styles.item__totals}>
					<div
						onClick={() => {
							setOrderSummary(!orderSummary);
						}}>
						<MimaText color="var(--color-primary)">
							<span
								style={{
									display: 'flex',
									alignItems: 'center',
									gap: '1rem',
								}}>
								{orderSummary ? (
									<>
										Hide Order Summary <FaAngleUp />
									</>
								) : (
									<>
										Show Order Summary <FaAngleDown />
									</>
								)}
							</span>
						</MimaText>
						<MimaText variant="subtitleBold">
							{amountFormatter(catalogOrderInfo[0]?.currencyCode).format(
								preChargesTotal
							)}{' '}
						</MimaText>
					</div>
				</div>
			) : (
				''
			)}

			<>
				{isBelow800 ? (
					<>
						{orderSummary ? (
							<>
								<div className={styles.checkout__order__items}>
									{catalogOrderInfo?.map((x, i) => (
										<div className={styles.checkout__order__item} key={i}>
											<div>
												<img src={x?.imageUrl} alt="Product" />
												<div className={styles.item__quantity}>
													{x.quantity}
												</div>
											</div>
											<MimaText>
												{x.name} <br />{' '}
												{x.selectedVariant?.name !== x?.name && (
													<>({x?.selectedVariant?.name})</>
												)}
											</MimaText>

											<MimaText>
												{amountFormatter(x?.currencyCode).format(x?.amount)}
											</MimaText>
										</div>
									))}
								</div>

								<div className={styles.item__totals}>
									<div>
										<MimaText>Subtotal</MimaText>
										<MimaText>{totalPrice}</MimaText>
									</div>
									<div>
										<MimaText>Shipping</MimaText>
										<MimaText>
											{totalPrice >
												newShippingInfo?.freeShippingForOrderAbove &&
											newShippingInfo?.freeShippingForOrderAbove !== 0
												? 'FREE'
												: newShippingInfo?.shippingFee
													? amountFormatter(
															catalogOrderInfo[0]?.currencyCode
														).format(newShippingInfo?.shippingFee)
													: 'FREE'}{' '}
										</MimaText>
									</div>
									<div>
										<MimaText variant="subtitleBold">Total</MimaText>
										<MimaText variant="subtitleBold">
											{amountFormatter(
												catalogOrderInfo[0]?.currencyCode
											).format(preChargesTotal)}
										</MimaText>
									</div>
								</div>
							</>
						) : (
							''
						)}
					</>
				) : (
					<>
						<div className={styles.checkout__order__items}>
							{catalogOrderInfo?.map((x, i) => (
								<div className={styles.checkout__order__item} key={i}>
									<div>
										<img src={x?.imageUrl} alt="Product" />
										<div className={styles.item__quantity}>{x.quantity}</div>
									</div>
									<MimaText>
										{x.name} <br />
										{x.selectedVariant?.name !== x?.name && (
											<>({x?.selectedVariant?.name})</>
										)}
									</MimaText>

									<MimaText>
										{amountFormatter(x?.currencyCode).format(x?.amount)}
									</MimaText>
								</div>
							))}
						</div>

						<div className={styles.item__totals}>
							<div>
								<MimaText>Subtotal</MimaText>
								<MimaText>{totalPrice}</MimaText>
							</div>
							<div>
								<MimaText>Shipping</MimaText>
								<MimaText>
									{totalPrice > newShippingInfo?.freeShippingForOrderAbove &&
									newShippingInfo?.freeShippingForOrderAbove !== 0
										? 'FREE'
										: newShippingInfo?.shippingFee
											? amountFormatter(
													catalogOrderInfo[0]?.currencyCode
												).format(newShippingInfo?.shippingFee)
											: 'FREE'}{' '}
								</MimaText>
							</div>
							<div>
								<MimaText variant="subtitleBold">Total</MimaText>
								<MimaText variant="subtitleBold">
									{amountFormatter(catalogOrderInfo[0]?.currencyCode).format(
										preChargesTotal
									)}
								</MimaText>
							</div>
						</div>
					</>
				)}
			</>
		</div>
	);
};

export default OrderSummary;
