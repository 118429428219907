import React, { useMemo, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { RemoveScroll } from 'react-remove-scroll';
import styles from './ServiceModal.module.scss';
import MimaText from '../MimaText';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/style.css';
import { MdCancel, MdOutlineTimer } from 'react-icons/md';
import Switch from '../Switch';
import { CiCalendar } from 'react-icons/ci';
import { IoInformationCircleOutline } from 'react-icons/io5';
import { MimaButton } from '../Button';
import { useMediaQuery } from 'react-responsive';

const ServiceModal = ({
	closeModal,
	singleStyle,
	values,
	variations,
	setFieldValue,
	formatMinutes,
	handleSubmit,
}) => {
	const today = new Date();

	const nextMonth = new Date(today.getFullYear(), today.getMonth() + 2, 0);

	const getUnavailableDays = availableDays => {
		const daysMap = {
			SUNDAY: 0,
			MONDAY: 1,
			TUESDAY: 2,
			WEDNESDAY: 3,
			THURSDAY: 4,
			FRIDAY: 5,
			SATURDAY: 6,
		};

		const allDays = [0, 1, 2, 3, 4, 5, 6];
		const availableIndices = availableDays.map(
			day => daysMap[day.toUpperCase()]
		);
		return allDays.filter(day => !availableIndices.includes(day));
	};

	const generateTimeSteps = (startHour, endHour, is24Hour) => {
		const steps = [];
		const start = startHour * 60; // Convert hours to minutes
		const end = endHour * 60; // Convert hours to minutes

		for (let time = start; time < end; time += 15) {
			const hours = Math.floor(time / 60);
			const minutes = time % 60;

			const formattedTime = is24Hour
				? `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}` // 24-hour format
				: `${hours % 12 || 12}:${minutes.toString().padStart(2, '0')} ${hours >= 12 ? 'PM' : 'AM'}`; // 12-hour format

			steps.push(formattedTime);
		}

		return steps;
	};

	const [is24Hour, setIs24Hour] = useState(true);
	const [step, setStep] = useState(1);
	const [mobileBookTime, setMobileBookTime] = useState(false);

	const timeSteps = useMemo(() => {
		return generateTimeSteps(
			singleStyle?.availableWindow.startHour,
			singleStyle?.availableWindow.endHour,
			is24Hour
		);
	}, [is24Hour, singleStyle?.availableWindow]);

	const calculateEndTime = (startTime, durationInMinutes, is24HourFormat) => {
		let hours;
		let minutes;

		if (is24HourFormat) {
			// Parse the 24-hour format (e.g., "08:00")
			[hours, minutes] = startTime.split(':').map(Number);
		} else {
			// Parse the AM/PM format (e.g., "8:00 AM")
			const [time, modifier] = startTime.split(' ');
			[hours, minutes] = time.split(':').map(Number);

			// Convert to 24-hour format if it's PM
			if (modifier === 'PM' && hours < 12) {
				hours += 12;
			}
			if (modifier === 'AM' && hours === 12) {
				hours = 0;
			}
		}

		// Create a Date object for the start time
		const startDate = new Date();
		startDate.setHours(hours, minutes, 0, 0);

		// Add the duration in minutes
		const endDate = new Date(startDate.getTime() + durationInMinutes * 60000);

		// Extract the end time
		const endHours = endDate.getHours();
		const endMinutes = endDate.getMinutes();

		if (is24HourFormat) {
			// Return in 24-hour format
			const formattedHours = endHours.toString().padStart(2, '0');
			const formattedMinutes = endMinutes.toString().padStart(2, '0');
			return `${formattedHours}:${formattedMinutes}`;
		} else {
			// Convert to 12-hour format with AM/PM
			const formattedHours = endHours % 12 || 12; // Convert to 12-hour format
			const formattedMinutes = endMinutes.toString().padStart(2, '0');
			const endModifier = endHours >= 12 ? 'PM' : 'AM';
			return `${formattedHours}:${formattedMinutes} ${endModifier}`;
		}
	};

	const serviceEndTime = useMemo(() => {
		return calculateEndTime(
			values?.selectedTime,
			values?.selectedVariant?.durationInMinutes,
			is24Hour
		);
	}, [
		is24Hour,
		values?.selectedVariant?.durationInMinutes,
		values.selectedTime,
	]);

	const selectedDateStringHandler = selectedDate => {
		const date = new Date(selectedDate);

		const formattedDate = date.toDateString();

		const timeZoneName = date.toString().match(/\(([^)]+)\)/)?.[1]; // "West Africa Standard Time"

		// Combine the two
		const result = `${formattedDate} (${timeZoneName})`;

		return result;
	};

	const selectedDateString = useMemo(() => {
		return selectedDateStringHandler(values?.selectedDate);
	}, [values?.selectedDate]);

	const isBelow700 = useMediaQuery({
		query: '(max-width: 700px)',
	});

	return (
		<div className="modal">
			<RemoveScroll>
				<AnimatePresence>
					<motion.div
						initial={{
							opacity: 0,
						}}
						animate={{
							opacity: 1,
							transition: {
								duration: 0.2,
							},
						}}
						exit={{
							opacity: 0,
							transition: {
								duration: 0.2,
							},
						}}
						className={styles.background}
						onClick={closeModal}>
						{step === 1 ? (
							<>
								{isBelow700 ? (
									<div
										className={`${styles.container} ${styles.container__select}`}
										onClick={e => {
											e.stopPropagation();
										}}>
										{!mobileBookTime ? (
											<div className={styles.calendar}>
												{singleStyle?.imageUrls?.length > 0 ? (
													<div className={styles.calendar__img}>
														<img
															src={singleStyle?.imageUrls?.[0]}
															alt={singleStyle?.name}
														/>
													</div>
												) : (
													''
												)}
												<MimaText color="var(--color-white)">
													{singleStyle?.name}{' '}
													{variations?.length > 1 &&
														values.selectedVariant?.name !==
															singleStyle?.name &&
														`- (${values.selectedVariant?.name})`}
												</MimaText>
												<DayPicker
													mode="single"
													selected={values.selectedDate}
													onSelect={day => {
														setFieldValue('selectedDate', day);
														if (day) {
															setMobileBookTime(true);
														}
													}}
													footer={
														values.selectedDate
															? `Selected: ${values.selectedDate.toLocaleDateString()}`
															: 'Pick a day.'
													}
													classNames={{
														today: styles.rdp__today,
														selected: styles.rdp__selected,
														chevron: styles.rdp__chevron,
														nav: styles.rdp__nav,
														month_caption: styles.rdp__month,
													}}
													disabled={[
														{ before: today },
														{ after: nextMonth },
														{
															dayOfWeek: getUnavailableDays(
																singleStyle?.availableDays
															),
														},
													]}
													startMonth={today}
													endMonth={nextMonth}
												/>
											</div>
										) : (
											<div className={styles.time}>
												<div className={styles.close}>
													<MdCancel onClick={closeModal} />
												</div>
												<MimaText
													color="var(--color-grey-6)"
													align="center"
													mb={2}>
													Book a{' '}
													{formatMinutes(
														values?.selectedVariant?.durationInMinutes
													)}{' '}
													appointment
												</MimaText>
												<div className={styles.time__type}>
													<MimaText variant="smallBold" mb={3}>
														What time works best?
													</MimaText>
													<Switch
														title={'am/pm'}
														switchClick={() => {
															setIs24Hour(!is24Hour);
														}}
													/>
												</div>
												{values?.selectedDate ? (
													<div className={styles.time__steps}>
														{timeSteps.map((time, i) => (
															<div
																key={i}
																className={styles.time__step}
																onClick={() => {
																	setFieldValue('selectedTime', time);
																	setFieldValue(
																		'bookingPeriod',
																		`${time} - ${calculateEndTime(
																			time,
																			values?.selectedVariant
																				?.durationInMinutes,
																			is24Hour
																		)}, 
											                ${selectedDateString}`
																	);
																	setStep(2);
																}}>
																{time}
															</div>
														))}
													</div>
												) : (
													''
												)}
												<MimaButton
													title="Back"
													onClick={() => {
														setMobileBookTime(false);
													}}
													variant="outlined"
													height={4.8}
													mt={2}
												/>
											</div>
										)}
									</div>
								) : (
									<div
										className={`${styles.container} ${styles.container__select}`}
										onClick={e => {
											e.stopPropagation();
										}}>
										<div className={styles.calendar}>
											{singleStyle?.imageUrls?.length > 0 ? (
												<div className={styles.calendar__img}>
													<img
														src={singleStyle?.imageUrls?.[0]}
														alt={singleStyle?.name}
													/>
												</div>
											) : (
												''
											)}
											<MimaText color="var(--color-white)">
												{singleStyle?.name}{' '}
												{variations?.length > 1 &&
													values.selectedVariant?.name !== singleStyle?.name &&
													`- (${values.selectedVariant?.name})`}
											</MimaText>
											<DayPicker
												mode="single"
												selected={values.selectedDate}
												onSelect={day => {
													setFieldValue('selectedDate', day);
												}}
												footer={
													values.selectedDate
														? `Selected: ${values.selectedDate.toLocaleDateString()}`
														: 'Pick a day.'
												}
												classNames={{
													today: styles.rdp__today,
													selected: styles.rdp__selected,
													chevron: styles.rdp__chevron,
													nav: styles.rdp__nav,
													month_caption: styles.rdp__month,
												}}
												disabled={[
													{ before: today },
													{ after: nextMonth },
													{
														dayOfWeek: getUnavailableDays(
															singleStyle?.availableDays
														),
													},
												]}
												startMonth={today}
												endMonth={nextMonth}
											/>
										</div>
										<div className={styles.time}>
											<div className={styles.close}>
												<MdCancel onClick={closeModal} />
											</div>
											<MimaText
												color="var(--color-grey-6)"
												align="center"
												mb={2}>
												Book a{' '}
												{formatMinutes(
													values?.selectedVariant?.durationInMinutes
												)}{' '}
												appointment
											</MimaText>
											<div className={styles.time__type}>
												<MimaText variant="smallBold" mb={3}>
													What time works best?
												</MimaText>
												<Switch
													title={'am/pm'}
													switchClick={() => {
														setIs24Hour(!is24Hour);
													}}
												/>
											</div>
											{values?.selectedDate ? (
												<div className={styles.time__steps}>
													{timeSteps.map((time, i) => (
														<div
															key={i}
															className={styles.time__step}
															onClick={() => {
																setFieldValue('selectedTime', time);
																setFieldValue(
																	'bookingPeriod',
																	`${time} - ${calculateEndTime(
																		time,
																		values?.selectedVariant?.durationInMinutes,
																		is24Hour
																	)}, 
											                ${selectedDateString}`
																);
																setStep(2);
															}}>
															{time}
														</div>
													))}
												</div>
											) : (
												''
											)}
										</div>
									</div>
								)}
							</>
						) : (
							<div
								className={`${styles.container} ${styles.container__confirm}`}
								onClick={e => {
									e.stopPropagation();
								}}>
								<div className={styles.close}>
									<MdCancel onClick={closeModal} />
								</div>
								<MimaText variant="bodyBold" mb={2}>
									Confirm Booking
								</MimaText>
								<div className={styles.confirm__date}>
									<div>
										<CiCalendar />{' '}
										<p>
											{values?.selectedTime} - {serviceEndTime},{' '}
											{selectedDateString}
										</p>
									</div>
									<div>
										<MdOutlineTimer style={{ color: 'var(--color-grey)' }} />
										<p>
											{formatMinutes(
												values?.selectedVariant?.durationInMinutes
											)}
										</p>
									</div>
								</div>
								<div className={styles.confirm__info}>
									<IoInformationCircleOutline />{' '}
									<div>
										<p className="mb-2">
											Booking will only be confirmed after checkout
										</p>
										<p>
											You will receive an email confirmation and detailed
											calendar invite after checking out.
										</p>
									</div>
								</div>
								<div className={styles.confirm__buttons}>
									<MimaButton
										title="Back"
										onClick={() => {
											setStep(1);
										}}
										variant="outlined"
										height={4.8}
									/>
									<MimaButton
										title="Confirm & Checkout"
										variant="contained"
										onClick={() => {
											setFieldValue('action', 'SERVICE CHECKOUT');
											handleSubmit();
										}}
										height={4.8}
									/>
								</div>
							</div>
						)}
					</motion.div>
				</AnimatePresence>
			</RemoveScroll>
		</div>
	);
};

export default ServiceModal;
