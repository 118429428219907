import React, { useState } from 'react';
import MimaText from '../MimaText';
import styles from './Switch.module.scss';

const Switch = ({ title, switchClick }) => {
	const [switchOn, setSwitchOn] = useState(false);

	const switchHandler = () => {
		setSwitchOn(!switchOn);
		if (switchClick) {
			switchClick();
		}
	};
	return (
		<div className={styles.switch}>
			<MimaText>{title}</MimaText>
			<div
				className={`${switchOn ? styles.switch__on : styles.switch__off} ${styles.switch__gen}`}
				onClick={switchHandler}>
				<div
					className={`${switchOn ? styles.switch__nob__on : ''} ${styles.switch__nob}`}></div>
			</div>
		</div>
	);
};

export default Switch;
